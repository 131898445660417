import { React, useState } from 'react';
import './checkbox.css';

const Checkbox = ({ children, onChange, id, checkBoxClass, isRequired }) => {
  const [isChecked, setIsChecked] = useState(false);
  
  const handleSetIsChecked = () => {
    setIsChecked((prev) => !prev);
    
    // Notify parent component of the change
    if (onChange) {
      onChange(!isChecked); // We use !isChecked because state hasn't updated yet
    }
  }
  
  return (
    <label htmlFor={id} className={`form-checkbox-label-container ${checkBoxClass}`}>
      <input
        id={id}
        type="checkbox"
        required={isRequired}
        checked={isChecked}
        onChange={handleSetIsChecked}
      />
      <span className='check-span'>{children}</span>
    </label>
  );
}

export default Checkbox;
