import React from 'react';
import './product-roadmap-card.css';

const ProductRoadmapCard = (props) => {
  const { card } = props;

  return (
    <div className={`product-roadmap-card text-dk ${card.cardClass}`} >
      <div className="product-roadmap-card-wrapper">
        <div className={card.iconClass}>
          {card.icon}
        </div>
        <h3 className='title-sub-2 text-lt'>
          {card.title}
        </h3>
        <p className="product-roadmap-content paragraph-lt semi-bold">
          {card.content}
        </p>
        <div className='product-roadmap-card-link'>
          <h4 className={card.statusColor}>
            {card.status}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default ProductRoadmapCard;