import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './roadmap.css';
import RoleBox from '../../elements/role-box/RoleBox';
import { fieldChoiceData as data } from '../../../data/fieldChoiceData';
import Dashboard from '../dashboard/Dashboard';

const Roadmap = () => {
  const location = useLocation();
  const [departmentIndex, setDepartmentIndex] = useState(null);
  const [roleIndex, setRoleIndex] = useState(null);
  const [prompts, setPrompts] = useState(null);
  
  useEffect(() => {
    setDepartmentIndex(null);
    setRoleIndex(null);
    setPrompts(null);
  }, [location]);
  
  useEffect(() => {
    setRoleIndex(null);
  }, [departmentIndex]);
  
  const handleDepartmentChange = (event) => {
    setDepartmentIndex(event.target.value);
  };
  
  const handleTaskChange = (event) => {
    setRoleIndex(event.target.value);
  };
  
  const setClipboard = (text) => {
    navigator.clipboard
      .writeText(text.prompt)
      .then(() => alert('Copied!'))
      .catch((err) =>
        alert('Error copying to clipboard: ' + err
        ));
  }
  
  const selectedDepartment = departmentIndex !== null ? data[departmentIndex] : null;
  const selectedRole = selectedDepartment && roleIndex !== null ? selectedDepartment.tasks[roleIndex] : null;
  
  useEffect(() => {
    selectedRole && selectedRole.prompts ? setPrompts(selectedRole.prompts) : setPrompts(null);
  }, [selectedRole]);
  
  const sidebarContent = <div className='dropdown-list-department'>
      <label htmlFor="department" className='paragraph-sub-4 text-gradient-blue semi-bold'></label>
      <select
        onChange={(e) => handleDepartmentChange(e)}
        className='dropdown-dk-purple'>
        <option defaultValue='Select'>Start Here</option>
        {data.map((item, index) => (
          <option key={index} value={index}>
            {item.department}
          </option>
        ))}
      </select>
    </div>;
  
  const contentBoxContent =
    <div className='content'>
    { selectedDepartment ?
      <div className='dropdown-list-task'>
        <label className='title-sub-3 semi-bold label-pad' htmlFor="task">
          Role
        </label>
        <select
          onChange={(e) => handleTaskChange(e)}
           value={roleIndex || ''}
           id="task"
          className='dropdown-dk-purple'
           >
          <option key='initialSelect'
            defaultValue='Select'>
            Select Role
          </option>
          {selectedDepartment &&
            selectedDepartment.tasks.map(((role, index) => (
            <option key={index}
              value={index}>
              {role.title}
            </option>
          )))}
        </select>
      </div>:
      <p className='light-bold'>This is the the place to find out about all of the ministry and building opportunities that we are working on. <br/><br/><strong>Here's how it works:</strong> <br/><br/><strong>1. Pick Your Field:</strong> Start by selecting the field you feel called to, or feel free to look around at the various fields we are working in. There is a place for everyone in God's Kingdom, so don't hesitate to reach out if you are not finding what you need or if you need any assistance at all. <br/><br/><strong>2. Explore:</strong> Next, you will select from a dropdown to find in-depth information for your chosen field.<br/><br/><strong>Next:</strong> To assist you further, head on over to 'The Process' tab where you will find extensive information on how this all works. Building a kingdom involves many moving parts, but together we can build in record time! <br/><br/>We look forward to building with you!
      </p>
    }
    {selectedDepartment ?
      <ul className='prompt-list'>
        {prompts ?
          prompts.map((prompt, index) => (
            <RoleBox
              prompt={prompt}
              role={selectedRole}
              index={index}
              key={index}
              setClipboard={setClipboard}/>
          )) :
          <RoleBox
            prompt={null}
            role='Select Role'
            index='na'
            setClipboard={setClipboard}/>}
      </ul> :
      ''
    }
  </div>;
  
  return (
    <Dashboard
      content={sidebarContent}
      section='ROADMAP'
      contentBoxContent={contentBoxContent}
      contentBoxSection={selectedDepartment?.department ? selectedDepartment.department : 'Welcome!'}
      department={selectedDepartment?.department}
      prompts={prompts}
    />
  );
};

export default Roadmap;
