import React from 'react';
import './pill-hollow.css';

const PillHollow = (props) => {
  const { content, icon } = props;
  
  return (
    <div className='container-pill-hollow'>
      {icon}
      <p className='pill-hollow'>
        {content}
      </p>
    </div>
  );
}

export default PillHollow;