import React from 'react';

// eslint-disable-next-line react/prop-types
const CustomFormInput = ({ field, form: { touched, errors }, ...props }) => {
  // eslint-disable-next-line react/prop-types
  const isError = touched[field.name] && errors[field.name];

  return (
    <div className="input-container">
      <input
        {...field}
        {...props}
        className={`custom-input ${isError ? 'input-error' : ''}`}
      />
      {/* eslint-disable-next-line react/prop-types */}
      {isError && <div className="error">{errors[field.name]}</div>}
    </div>
  );
};



export default CustomFormInput;