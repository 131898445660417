import React from 'react';
import './side-image.css';

const SideImage = (props) => {
  const { imgClass, imgSrc, altText } = props;
  
  return (
    <img className={`${imgClass} side-image`} src={imgSrc} alt={altText}/>
  );
}

export default SideImage;
