import { React, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import './hero.css';
import CTAButton from '../cta-button/CTAButton';
import RightArrowCircular from '../elements-icons/RightArrowCircular';

const Hero = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      // Try to get the element by ID
      let element = document.getElementById(location.hash.substring(1));
      
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If there's no hash, scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]); // Re-run the effect whenever the location changes
  
  return (
    <section className='section-hero'>
      <div className='title-main'>
        <div className='title-hero title-hero-1 bold'>
          BUILDING
        </div>
        <div className='title-hero title-hero-2 bold'>
          SOLUTIONS
        </div>
        <div className='title-hero title-hero-3 bold'>
          FOR THE
        </div>
        <div className='title-hero title-hero-4 bold'>
          KINGDOM
        </div>
      </div>
      <div className='hero-cta'>
        <CTAButton url='/#meet-tethyr' icon={<RightArrowCircular svgClass='icon-md' pathClass=''/>}
                   className='btn-cta btn-hollow text-lt' content="Stronger Together!"/>
      </div>
    </section>
  );
}

export default Hero;