import React from 'react';

const RightArrowCircular = (props) => {
  const { svgClass, pathClass, width, height } = props;
  
  return (
    <svg id="Layer_2" className={svgClass} width={width} height={height} data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.98 38.99">
      <g id="Calque_1" data-name="Calque 1">
        <path className={pathClass}
              d="m19.49,0C8.74,0,0,8.75,0,19.5s8.74,19.49,19.49,19.49,19.49-8.75,19.49-19.49S30.24,0,19.49,0Zm9.2,20.39h0s-6.97,6.98-6.97,6.98c-.49.49-1.3.49-1.8,0-.49-.5-.49-1.31,0-1.8l4.81-4.81h-14.39c-.7,0-1.27-.57-1.27-1.27s.57-1.26,1.27-1.26h14.39l-4.81-4.81c-.49-.5-.49-1.3,0-1.8.5-.49,1.31-.49,1.8,0l6.96,6.97s.01,0,.01.01c.25.24.37.57.37.89s-.12.66-.37.9Z"/>
      </g>
    </svg>
  );
}

export default RightArrowCircular;
