import React from 'react';

const TechLines2 = (props) => {
  const { width, height, fillClass } = props;
  
  return (
    <svg id="Layer_1" width={width} height={height} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370.58 127.86">
      <path className={fillClass ? fillClass : "fill-pink"}
            d="m330.73,127.86c-4.26,0-7.73-3.47-7.73-7.73s3.47-7.73,7.73-7.73,7.73,3.47,7.73,7.73-3.47,7.73-7.73,7.73Zm0-13.46c-3.16,0-5.73,2.57-5.73,5.73s2.57,5.73,5.73,5.73,5.73-2.57,5.73-5.73-2.57-5.73-5.73-5.73Z"/>
      <path className={fillClass ? fillClass : "fill-pink"} d="m324,121.13h-65.55l-35.83-35.83H0v-2h223.45l35.83,35.83h64.72v2Z"/>
    </svg>
  );
}

export default TechLines2;