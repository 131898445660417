import React from 'react';
import { useLocation } from 'react-router-dom';
import './footer.css';

const Footer = () => {
  const year = new Date().getFullYear();
  const location = useLocation();
  
  const transparentRoutes = ['/'];
  
  // If on a transparent route
  const footerSetClass = transparentRoutes.includes(location.pathname) ?
    'section-footer' :
    'section-footer-fixed';
  const footerSetBG = transparentRoutes.includes(location.pathname) ?
    '' :
    'bg-dk';
  
  return (
    <footer className={`${footerSetClass} section-footer text-lt`}>
      <div className={`${footerSetBG} wrapper-footer`}>
        <div className='wrapper-footer-left'>
          {/*<Link
            to='/privacy-policy'
            target='_blank'
            className='paragraph-sub-5 text-gradient-blue semi-bold'>
            Privacy Policy
          </Link>*/}
        </div>
        <div className='wrapper-footer-center'>
          <p className='paragraph-sub-5'>
            © {year} goshinelife.com
          </p>
        </div>
        {/*<div className='wrapper-footer-right paragraph-sub-5'>
          <div className='bg-straight-gradient-purple icon-card-circle'>
            <Link to='/' className='icon-card-circle'>
              <SocialFacebook1
                svgClass='icon-md'
                pathClass='icon-wht' />
            </Link>
          </div>
          <div className='bg-frost icon-card-circle'>
            <Link to='/' className='icon-card-circle' >
              <SocialTwitter1
                svgClass='icon-md'
                pathClass='icon-wht' />
            </Link>
          </div>
          <div className='bg-frost icon-card-circle'>
            <Link to='/' className='icon-card-circle' >
              <SocialInstagram1
                svgClass='icon-md'
                pathClass='icon-wht' />
            </Link>
          </div>
        </div>*/}
      </div>
    </footer>
  );
};

export default Footer;
