import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Label, FormGroup, Row, Col } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomFormInput from "../../../elements/forms/CustomFormInput";
import AuthLogo from "../../../../assets/logo/tethyr_logo_lt_text.svg";
import { registerUser, loginUser } from '../../../../data/api/tethyr/auth'; // Import API calls
import '../auth.css';
import './register.css';
import Checkbox from "../../../elements/elements-form/Checkbox";

const RegisterFormik = () => {
  const navigate = useNavigate(); // For navigation after successful registration and login

  const initialValues = {
    firstName: '',
    lastName: '',
    UserName: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    UserName: Yup.string().required('User Name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
  });

  const handleSubmit = async (fields, { setSubmitting }) => {
    try {
      // Register the user
      const registerResponse = await registerUser({
        firstName: fields.firstName,
        lastName: fields.lastName,
        username: fields.UserName,
        email: fields.email,
        password: fields.password,
      });

      console.log('Registration successful:', registerResponse);

      // Log the user in
      const loginResponse = await loginUser({
        identifier: fields.email,
        password: fields.password,
      });

      const { token } = loginResponse;

      // Store the token and redirect
      localStorage.setItem('authToken', token);
      navigate('/');
      console.log('Registration and login successful!');
    } catch (error) {
      console.error('Error during registration or login:', error.response?.data || error.message);
      console.log('Registration or login failed. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="login-box text-lt">
      <div className="container-auth">
        <Link to="/">
          <img src={AuthLogo} alt="Logo" className="auth-logo"/>
        </Link>
        <div className="container-auth-card">
          <div className="auth-col auth-col-title">
            <h3 className="text-purple">Register</h3>
            <p className="paragraph-lt-sub">
              Already have an account? <Link to="/auth/login" className="">Login</Link>
            </p>
          </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              render={({errors, touched, isSubmitting}) => (
                <div className="form-auth">
                  <Form>
                    <Row className="auth-row">
                      <Col className="auth-col" >
                        <FormGroup>
                          <Label htmlFor="firstName" className="auth-label" >First Name</Label>
                          <Field
                            name="firstName"
                            type="text"
                            component={CustomFormInput}
                            className={`field-auth ${
                              errors.firstName && touched.firstName ? ' is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="auth-col" >
                        <FormGroup>
                          <Label htmlFor="lastName" className="auth-label">Last Name</Label>
                          <Field
                            name="lastName"
                            type="text"
                            component={CustomFormInput}
                            className={`field-auth ${
                              errors.lastName && touched.lastName ? ' is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="auth-row">
                      <Col className="auth-col" >
                        <FormGroup>
                          <Label htmlFor="UserName" className="auth-label">User Name</Label>
                          <Field
                            name="UserName"
                            type="text"
                            component={CustomFormInput}
                            className={`field-auth ${
                              errors.UserName && touched.UserName ? ' is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage
                            name="UserName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="auth-col" >
                        <FormGroup>
                          <Label htmlFor="email" className="auth-label">Email</Label>
                          <Field
                            name="email"
                            type="text"
                            component={CustomFormInput}
                            className={`field-auth ${
                              errors.email && touched.email ? ' is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="auth-row">
                      <Col className="auth-col" >
                        <FormGroup>
                          <Label htmlFor="password" className="auth-label">Password</Label>
                          <Field
                            name="password"
                            type="password"
                            component={CustomFormInput}
                            className={`field-auth ${
                              errors.password && touched.password ? ' is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="auth-col" >
                        <FormGroup>
                          <Label htmlFor="confirmPassword" className="auth-label">Confirm Password</Label>
                          <Field
                            name="confirmPassword"
                            type="password"
                            component={CustomFormInput}
                            className={`field-auth ${
                              errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="auth-row">
                      <Col className="auth-col" >
                        <FormGroup inline className="form-check">
                          <Checkbox
                            type="checkbox"
                            label='Accept Terms & Conditions'
                            name="acceptTerms"
                            id="acceptTerms"
                            className={`field-auth-checkbox ${
                              errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : ''
                            }`}
                          />
                          <Label htmlFor="acceptTerms" className="form-check-label auth-label">
                            Accept Terms & Conditions
                          </Label>
                          <ErrorMessage
                            name="acceptTerms"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="auth-row auth-btn-row">
                      <Col className="auth-col" >
                        <FormGroup>
                          <Button type="submit" className="btn-ghost" disabled={isSubmitting}>
                            {isSubmitting ? 'Registering...' : 'Register'}
                          </Button>
                          <Button type="reset" className="btn-ghost">
                            Reset
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            />
        </div>
      </div>
    </div>
  );
};

export default RegisterFormik;
