import React from 'react';

const ContactMessage1 = (props) => {
  const { svgClass, pathClass, width, height } = props;
  
  return (
    <svg id="Layer_2" className={svgClass} width={width} height={height} viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
      <g id="Message">
        <g id="Iconly/Light-Outline/Message">
          <g id="Message_2">
            <path id="Combined-Shape" className={pathClass} fillRule="evenodd" clipRule="evenodd" d="M11.1063 1.58057C13.3263 1.58057 15.0003 3.3919 15.0003 5.7939V10.3726C15.0003 11.6019 14.5657 12.7126 13.7757 13.5006C13.067 14.2066 12.1477 14.5806 11.117 14.5806H4.54833C3.51966 14.5806 2.60099 14.2072 1.89166 13.5006C1.10166 12.7126 0.666992 11.6019 0.666992 10.3726V5.7939C0.666992 3.3919 2.34099 1.58057 4.56099 1.58057H11.1063ZM11.1063 2.58057H4.56099C2.88433 2.58057 1.66699 3.9319 1.66699 5.7939V10.3726C1.66699 11.3346 1.99766 12.1939 2.59766 12.7919C3.11499 13.3086 3.79033 13.5806 4.55033 13.5806H11.1063C11.1077 13.5792 11.113 13.5806 11.117 13.5806C11.8777 13.5806 12.5523 13.3086 13.0697 12.7919C13.6703 12.1939 14.0003 11.3346 14.0003 10.3726V5.7939C14.0003 3.9319 12.783 2.58057 11.1063 2.58057ZM12.157 5.66643C12.331 5.88043 12.2983 6.1951 12.0843 6.36977L9.12166 8.77777C8.74699 9.0751 8.29899 9.22377 7.85166 9.22377C7.40566 9.22377 6.96099 9.07643 6.58899 8.78177L3.59899 6.3711C3.38366 6.19777 3.35033 5.88243 3.52299 5.66777C3.69699 5.45377 4.01166 5.41977 4.22633 5.59243L7.21366 8.00043C7.58899 8.29777 8.11766 8.29777 8.49566 7.99777L11.453 5.59377C11.6677 5.41843 11.9823 5.45177 12.157 5.66643Z"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ContactMessage1;