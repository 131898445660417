import React from 'react';
import Sidebar from '../../elements/sidebar/Sidebar';
import ContentBox from '../../elements/content-box/ContentBox';
import './dashboard.css';
import Header from '../../elements/header/Header';
import Footer from '../../elements/footer/Footer';

const Dashboard = ({content, section, contentBoxContent, contentBoxSection, department, prompts}) => {
  return (
    <div className='dashboard-wrapper'>
      <Header />
      <div className='dashboard-content-container'>
        <Sidebar
          content={content}
          section={section} />
        <ContentBox
          content={contentBoxContent}
          section={contentBoxSection}
          department={department}
          prompts={prompts}
        />
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;