import React from 'react';
import './sidebar.css';

const Sidebar = ({ content , section }) => {
  
  return (
    <div className='sidebar-wrapper-container'>
      <div className="sidebar-wrapper text-wht">
        <div className='sidebar-title'>
          <h4 className=''>{section}</h4>
          {content}
        </div>
        <div className='sidebar-disclaimer'>
          {/*<p className='paragraph-sub-5'>
            <span className='text-gradient-blue'><strong>PLEASE NOTE</strong></span><br/><br/> Before using any AI prompts or tools, we advise you to check with your organization's policies and guidelines regarding AI usage. Some companies may have specific rules or tools that are whitelisted or forbidden.
          </p>*/}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
