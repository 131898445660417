import React from 'react';

const LighteningBolt1 = (props) => {
  const { svgClass, pathClass, width, height } = props;
  
  return (
    <svg id="Layer_1" className={svgClass} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.07 43.61">
      <g id="Calque_1" data-name="Calque 1">
        <path className={pathClass}
              d="m25,17.95c-.27-.53-.82-.38-1.5-.38h-7.78l3.6-15.72c.37-1.05.26-1.52,0-1.68-.34-.2-.56-.4-1.32.51-.01.02-.02.03-.04.05L.78,23.55c-.42.53-.99,1.24-.71,1.83.26.57.82.45,1.47.45h7.81l-3.65,15.99c-.31,1.07-.18,1.51.09,1.65.15.08.34.14.54.14.36,0,.32-.18.77-.77l17.2-23.19c.41-.53.96-1.17.7-1.7Zm-16.37,19.56l2.7-11.47c.19-.7-.05-1.18-.22-1.41-.33-.43-.84-.65-1.52-.65H2.9L16.42,6.02l-2.63,11.14c-.18.54-.12,1.09.18,1.53.32.47.86.74,1.48.74h6.59l-13.41,18.08Z"/>
        <path className={pathClass}
              d="m11.33,26.04c.19-.7-.05-1.18-.22-1.41-.33-.43-.84-.65-1.52-.65H2.9L16.42,6.02l-2.63,11.14c-.18.54-.12,1.09.18,1.53.32.47.86.74,1.48.74h6.59l-13.41,18.08,2.7-11.47Z"/>
      </g>
    </svg>
  );
}

export default LighteningBolt1;