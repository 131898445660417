export const fieldChoiceData = [
  {
    department: 'Spiritual',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      ],
  },
  {
    department: 'Food & Water',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Housing',
    tasks: [
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Energy',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Logistics',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Entertainment/Community',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Legal',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Medical',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Engineering',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Education',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Clothing',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Technology',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
  {
    department: 'Real Estate',
    tasks: [
      { title: 'Elder Board',
        prompts: [
          {
            prompt: 'As a [position title] in charge of creating a project plan, draft a memo to your project team. The project is titled [project name], and it is set to commence on [start date] with a target completion date of [end date]. Be sure to outline the key milestones, assign the necessary roles and responsibilities, and indicate any essential resources or materials required. Also, provide details about the project\'s overall objectives, deliverables, and risk management strategies. Encourage the team members to review the plan and be prepared to discuss it in the upcoming [project meeting date] meeting.',
            documentation: 'To use this prompt, fill in your specific details for the position title, project name, start and end dates, milestones, roles, resources, and project meeting date. To customize, you can adjust the project\'s objectives, deliverables, and risk strategies, or include additional information such as expected challenges or contingency plans.',
          }
        ]
      },
      { title: 'Team Structure',
        prompts: [
          {
            prompt: 'As a [position title], you are responsible for assigning tasks to your team members for an upcoming project. Draft an email to your team members outlining their individual tasks. The project is titled [Project Title] and is scheduled to start on [start date] and expected to end on [end date]. The tasks that need to be divided among the team members are [list of tasks]. Ensure to specify the role of each member, detail the objectives of their tasks, deadlines, and any necessary materials or resources they might require to complete their tasks. Also, encourage them to reach out if they have any questions or need further clarification.',
            documentation: 'To utilize this prompt, replace the placeholders for the position title, project title, start and end dates, and the list of tasks with your own specific details. To customize, adjust the nature of the tasks, deadlines, resources required, or add further instructions such as progress update schedules or expectations on task completion.',
          }
        ]
      },
      { title: 'Team LandingProduct',
        prompts: [
          {
            prompt: 'As a [position title] responsible for tracking project progress, write an update email to your team. The project in question is [project name], which started on [start date] and is due to be completed by [end date]. Make sure to mention the current status of the project, key milestones achieved, any issues or challenges encountered, the next steps, and a reminder about the importance of each team member\'s contributions to the timely and successful completion of the project.',
            documentation: 'To utilize this prompt, substitute the placeholders for the position title, project name, start date, and end date with your specific information. For personalization, consider adjusting the areas of project status, milestones, challenges, next steps, or add extra elements such as individual tasks or acknowledgments.',
          }
        ]
      },
      { title: 'Opportunities',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
      { title: 'Team Contact',
        prompts: [
          {
            prompt: 'As a [position title] assigned to report project completion, draft an email to your stakeholders. The project, [project name], has been completed as of [completion date] and the results are ready for review. Ensure to summarize key achievements, explain any deviations from the original plan, indicate the lessons learned, and propose the next steps or follow-up projects if applicable. Remember to attach the final project report for their perusal.',
            documentation: 'To utilize this prompt, substitute placeholders for position title, project name, and completion date with your specific information. For customization, you can adjust the content to highlight specific project milestones, alter the complexity of project details, or include additional directives such as pre-report preparations or post-report follow-up actions.',
          }
        ]
      },
    ],
  },
]