import axios from './axiosConfig';

// Register a user
export const registerUser = async (userData) => {
  const response = await axios.post('/user/register', userData);
  return response.data;
};

// Login a user
export const loginUser = async (credentials) => {
  const response = await axios.post('/user/login', credentials);
  return response.data;
};

// Logout a user
export const logoutUser = async () => {
  const authToken = localStorage.getItem('authToken');
  if (!authToken) throw new Error('No token found.');

  await axios.post('/user/logout', {}, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

// Delete user account
export const deleteUser = async (token) => {
  const response = await axios.delete(`/user/${token}`);
  return response.data;
};