import React from "react";
import '../../pages/roadmap/roadmap.css';

const RoleBox = ({ prompt, role, index, setClipboard }) => {
  return (
    <li key={index}
        className='prompt-wrapper'>
      {/* Set Task Title */}
      {role?.title ?
        <h3
          className='title-sub-4 semi-bold'>
          {role.title}
        </h3> :
        ''
      }
      {/* Set Prompt */}
      <p className='paragraph-sub-4'>
      {prompt?.prompt ? prompt.prompt :
        !prompt?.prompt && role?.title ?
          'Coming Soon' : ''
      }
      </p>
    </li>
  );
}

export default RoleBox;