import React from 'react';
import './content-box.css';

const ContentBox = ({ content, section }) => {
  
  return (
    <div className='content-box-wrapper text-lt'>
      <div className='content-box-wrapper-container'>
        <div className='content-title'>
          <h1>{section}</h1>
        </div>
        <div className='content-wrapper'>
          {content}
        </div>
      </div>
    </div>
  );
};

export default ContentBox;
