import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Roadmap from './components/pages/roadmap/Roadmap';
import Landing from './components/pages/landing/Landing';
import NotFound from './components/pages/not-found/NotFound';
import PrivacyPolicy from './components/pages/privacy-policy/PrivacyPolicy';
import Login from "./components/pages/auth/login/Login";
import Register from "./components/pages/auth/register/Register";
import ResetPwd from "./components/pages/auth/resetpwd/ResetPwd";

const App = () => {
  return (
    <Router>
      <>
        <Routes>
          <Route path="/" element={ <Landing /> } />
          <Route path="/auth/login" element={ <Login /> } />
          <Route path="/auth/register" element={ <Register /> } />
          <Route path="/auth/reset-password" element={ <ResetPwd /> } />
          <Route path="/roadmap" element={ <Roadmap /> } />
          <Route path="/privacy-policy" element={ <PrivacyPolicy />} />
          <Route path="*" element={ <NotFound /> } />
        </Routes>
      </>
    </Router>
  );
}

export default App;
