import React from 'react';

const Monitor1 = (props) => {
  const { svgClass, pathClass, width, height } = props;
  
  return (
    <svg id="Layer_1" className={svgClass} width={width} height={height} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
      <path className={pathClass}
            d="m6.44,17.21c0-.11-.02-.22-.07-.32-.05-.1-.12-.19-.2-.26l-1.76-1.42c-.16-.13-.35-.19-.55-.17-.2.02-.38.12-.51.27-.06.08-.11.17-.14.26-.03.1-.04.2-.03.3,0,.1.04.2.09.28.05.09.11.17.19.23l1.03.84-1.03.85c-.15.13-.23.31-.25.51-.02.19.04.39.17.54.12.15.3.25.49.27.19.02.39-.03.55-.14l1.76-1.44c.09-.07.16-.17.21-.27.05-.11.07-.22.07-.34Z"/>
      <path className={pathClass}
            d="m9.12,18.73h-2.19c-.2,0-.39.08-.54.22-.14.14-.22.34-.22.54s.08.39.22.54c.14.14.34.22.54.22h2.19c.2,0,.39-.08.54-.22.14-.14.22-.34.22-.54s-.08-.39-.22-.54c-.14-.14-.34-.22-.54-.22Z"/>
      <path className={pathClass}
            d="m26.52,4.45c.18,0,.35-.05.5-.15.15-.1.26-.24.33-.41.07-.17.09-.35.05-.52-.03-.18-.12-.34-.25-.46-.13-.13-.29-.21-.46-.25-.18-.03-.36-.02-.52.05-.17.07-.31.18-.41.33-.1.15-.15.32-.15.5,0,.24.1.47.26.64.17.17.4.26.64.26Z"/>
      <path className={pathClass}
            d="m29.4,4.45c.18,0,.35-.05.5-.15.15-.1.26-.24.33-.41.07-.17.09-.35.05-.52-.03-.18-.12-.34-.25-.46-.13-.13-.29-.21-.46-.25-.18-.03-.36-.02-.52.05-.17.07-.31.18-.41.33-.1.15-.15.32-.15.5,0,.24.1.47.26.64s.4.26.64.26Z"/>
      <path className={pathClass}
            d="m32.29,4.45c.18,0,.35-.05.5-.15.15-.1.26-.24.33-.41.07-.17.09-.35.05-.52-.03-.18-.12-.34-.25-.46-.13-.13-.29-.21-.46-.25-.18-.03-.36-.02-.52.05-.17.07-.31.18-.41.33-.1.15-.15.32-.15.5,0,.24.1.47.26.64.17.17.4.26.64.26Z"/>
      <path className={pathClass}
            d="m4.45,4.39h9.94c.2,0,.39-.08.54-.22.14-.14.22-.34.22-.54s-.08-.39-.22-.54c-.14-.14-.34-.22-.54-.22H4.45c-.2,0-.39.08-.54.22-.14.14-.22.34-.22.54s.08.39.22.54c.14.14.34.22.54.22Z"/>
      <path className={pathClass}
            d="m34.11,0H3.89C2.86,0,1.87.41,1.14,1.14.41,1.87,0,2.86,0,3.89v21.66c0,1.03.41,2.02,1.14,2.75.73.73,1.72,1.14,2.75,1.14h10.09v3.59h-2.58c-.66,0-1.29.26-1.76.73-.47.47-.73,1.1-.73,1.76s.26,1.29.73,1.76c.47.47,1.1.73,1.76.73h15.2c.66,0,1.29-.26,1.76-.73.47-.47.73-1.1.73-1.76s-.26-1.29-.73-1.76c-.47-.47-1.1-.73-1.76-.73h-2.58v-3.59h10.09c1.03,0,2.02-.41,2.75-1.14s1.14-1.72,1.14-2.75V3.89c0-1.03-.41-2.02-1.14-2.75-.73-.73-1.72-1.14-2.75-1.14ZM1.52,7.13h34.96v14.99H1.52V7.13ZM3.89,1.52h30.22c.63,0,1.23.25,1.68.69.44.44.69,1.05.69,1.68v1.72H1.52v-1.72c0-.63.25-1.23.69-1.68.44-.44,1.05-.69,1.68-.69Zm23.7,33.99c0,.13-.03.26-.08.37-.05.12-.12.23-.22.32-.09.09-.2.16-.32.21-.12.05-.25.07-.38.07h-15.2c-.26,0-.5-.1-.68-.28-.18-.18-.28-.43-.28-.68s.1-.5.28-.68c.18-.18.43-.28.68-.28h15.2c.13,0,.26.02.38.07.12.05.23.12.32.21.09.09.17.2.22.32.05.12.08.25.08.37Zm-5.08-2.49h-7.01v-3.59h7.01v3.59Zm11.61-5.11H3.89c-.63,0-1.23-.25-1.68-.69-.44-.44-.69-1.05-.69-1.68v-1.92h34.96v1.92c0,.63-.25,1.23-.69,1.68s-1.05.69-1.68.69Z"/>
      <path className={pathClass}
            d="m22.68,24.9h-7.36c-.2,0-.39.08-.54.22-.14.14-.22.34-.22.54s.08.39.22.54c.14.14.34.22.54.22h7.36c.2,0,.39-.08.54-.22.14-.14.22-.34.22-.54s-.08-.39-.22-.54c-.14-.14-.34-.22-.54-.22Z"/>
      <path className={pathClass}
            d="m4.56,13.42h3.25c.2,0,.39-.08.54-.22.14-.14.22-.34.22-.54s-.08-.39-.22-.54c-.14-.14-.34-.22-.54-.22h-3.25c-.2,0-.39.08-.54.22-.14.14-.22.34-.22.54s.08.39.22.54c.14.14.34.22.54.22Z"/>
      <path className={pathClass}
            d="m18.1,11.9h-6.54c-.2,0-.39.08-.54.22-.14.14-.22.34-.22.54s.08.39.22.54c.14.14.34.22.54.22h6.54c.2,0,.39-.08.54-.22.14-.14.22-.34.22-.54s-.08-.39-.22-.54c-.14-.14-.34-.22-.54-.22Z"/>
      <path className={pathClass}
            d="m23.86,11.9h-2.28c-.2,0-.39.08-.54.22-.14.14-.22.34-.22.54s.08.39.22.54c.14.14.34.22.54.22h2.28c.2,0,.39-.08.54-.22.14-.14.22-.34.22-.54s-.08-.39-.22-.54c-.14-.14-.34-.22-.54-.22Z"/>
      <path className={pathClass}
            d="m33.44,11.9h-5.84c-.2,0-.39.08-.54.22-.14.14-.22.34-.22.54s.08.39.22.54c.14.14.34.22.54.22h5.84c.2,0,.39-.08.54-.22.14-.14.22-.34.22-.54s-.08-.39-.22-.54c-.14-.14-.34-.22-.54-.22Z"/>
      <path className={pathClass}
            d="m14.14,10.27h2.28c.2,0,.39-.08.54-.22s.22-.34.22-.54-.08-.39-.22-.54-.34-.22-.54-.22h-2.28c-.2,0-.39.08-.54.22s-.22.34-.22.54.08.39.22.54.34.22.54.22Z"/>
      <path className={pathClass}
            d="m11.16,9.51c0-.2-.08-.39-.22-.54s-.34-.22-.54-.22h-5.84c-.2,0-.39.08-.54.22-.14.14-.22.34-.22.54s.08.39.22.54c.14.14.34.22.54.22h5.82c.1,0,.2-.02.3-.05.09-.04.18-.09.25-.16.07-.07.13-.16.17-.25.04-.09.06-.19.06-.29Z"/>
      <path className={pathClass}
            d="m30.18,10.27h3.26c.2,0,.39-.08.54-.22.14-.14.22-.34.22-.54s-.08-.39-.22-.54c-.14-.14-.34-.22-.54-.22h-3.26c-.2,0-.39.08-.54.22-.14.14-.22.34-.22.54s.08.39.22.54c.14.14.34.22.54.22Z"/>
      <path className={pathClass}
            d="m27.2,9.51c0-.2-.08-.39-.22-.54s-.34-.22-.54-.22h-6.54c-.2,0-.39.08-.54.22s-.22.34-.22.54.08.39.22.54.34.22.54.22h6.54c.2,0,.39-.08.54-.22s.22-.34.22-.54Z"/>
    </svg>
  );
}

export default Monitor1;
