import React from 'react';
import './landingproduct.css';
import SideImage from '../side-image/SideImage';
import meetTethyrImageMobile from '../../../assets/misc/meet-tethyr-w-plushie_400x218.jpg';
import CTAButton from '../cta-button/CTAButton';
//import useMediaQuery from '../../../util/useMediaQuery'; // checks for media queries

const LandingProduct = () => {
  //const isMobile = useMediaQuery('(max-width: 768px)'); // Detect if screen width is <= 768px

  return (
    <section id="meet-tethyr" className='section-meet-tethyr landing-product'>
      <div className='content-meet-tethyr'>
        <h3 className="">
          What is <span className="tethyr-title">Tethyr?</span>
        </h3>
        <p className='paragraph-dk'>
          Tethyr is our flagship tool. Simply put, it is a platform built specifically for the Builders. As we build
          God's Kingdom, we will need to stay connected and organized. Share resources, ideas and ensure that everything
          we build is built to last.
        </p>
        <p className='paragraph-dk'>
          This is where Tethyr comes in. Keep scrolling to learn more!
        </p>
        <div className='tethyr-callout wrapper-cta-center-mobile'>
          <CTAButton url='/#tethyr-features-intro' className='btn-cta bg-straight-gradient-green text-lt drop-shadow-lifted lifted' content='Learn More'/>
        </div>
      </div>
      <div className="tethyr-visuals">
        <SideImage
          imgSrc={meetTethyrImageMobile}
          imgClass="tethyr-plushie tethyr-plushie-mobile"
          altText='Super Cute Tethyr Plushie'
        />
      </div>
    </section>
  );
};

export default LandingProduct;
