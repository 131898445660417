import React from 'react';
import './landing-links.css';
import logo from '../../../assets/logo/logo-full-sunburst-250x67.png';
import {Link} from 'react-router-dom';
import ContactMessage1 from '../elements-icons/ContactMessage1';

const LandingLinks = () => {
  return (
    <section className='section-landing-links text-lt'
             id='landing-contact'>
      <div className='container-landing-links'>
        <div className='wrapper-landing-links-logo'>
          <img src={ logo } alt='Go Shine Solutions Logo'   className='logo-sub' />
          <p className='paragraph-sub-4'>
            Building solutions for the Kingdom!
          </p>
        </div>
        {/*<div className='wrapper-landing-links-links'>
          <h4 className='title-sub-3'>
            Links
          </h4>
          <Link to='/prompt-library' className='paragraph-sub-4'>
            Link 1
          </Link>
          <Link to='/documentation' className='paragraph-sub-4'>
            Link 2
          </Link>
          <Link to='/training' className='paragraph-sub-4'>
            Training
          </Link>

          <Link to='/courses' className='paragraph-sub-4'>
            Premium Stuff
          </Link>

        </div>*/}
        {/*
      <div className='wrapper-landing-links-company'>
        <h4 className='title-sub-3'>
          Company
        </h4>
        <Link to='/' className='paragraph-sub-4'>
          About Us
        </Link>
        <Link to='/' className='paragraph-sub-4'>
          Customer Rights
        </Link>
        <Link to='/' className='paragraph-sub-4'>
          Career
        </Link>
        <Link to='/' className='paragraph-sub-4'>
          Press
        </Link>
        <Link to='/' className='paragraph-sub-4'>
          Blog
        </Link>
      </div>
      */}
        <div className='wrapper-landing-links-contact'>
          <h4 className='title-sub-3'>
            Contact
          </h4>
          <Link to='mailto:contact@goshinelife.com' className='paragraph-sub-4'>
            <ContactMessage1 svgClass='icon-sm' pathClass='icon-wht' /> contact@goshinelife.com
          </Link>
          {/*
          <div className='wrapper-footer-right paragraph-sub-5'>
            <div className='bg-straight-gradient-purple icon-card-circle-sm icon-container'>
              <Link to='/'>
                <SocialFacebook1
                  svgClass='icon-sm'
                  pathClass='icon-wht' />
              </Link>
            </div>
            <div className='bg-frost icon-card-circle-sm icon-container'>
              <Link to='/' >
                <SocialTwitter1
                  svgClass='icon-sm'
                  pathClass='icon-wht' />
              </Link>
            </div>
            <div className='bg-frost icon-card-circle-sm icon-container'>
              <Link to='/' >
                <SocialInstagram1
                  svgClass='icon-sm'
                  pathClass='icon-wht' />
              </Link>
            </div>
          </div>
          */}
        </div>
      </div>
    </section>
  );
}

export default LandingLinks;