import React from 'react';
import { Button, Label, FormGroup, Row, Col } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import CustomFormInput from "../../../elements/forms/CustomFormInput";
import AuthLogo from '../../../../assets/logo/tethyr_logo_lt_text.svg';
import { loginUser } from '../../../../data/api/tethyr/auth'; // Import the login API function
import '../auth.css';
import './login.css';
import Checkbox from "../../../elements/elements-form/Checkbox";

const LoginFormik = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const handleSubmit = async (fields, { setSubmitting }) => {
    try {
      // Call the login API
      const response = await loginUser({
        identifier: fields.email, // Assuming the login API expects `identifier` for email/username
        password: fields.password,
      });

      const { token } = response;

      // Store the token in localStorage
      localStorage.setItem('authToken', token);

      // Navigate to the home page
      navigate('/');

    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      // eslint-disable-next-line
      alert('Login failed. Please check your credentials and try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="login-box text-lt">
      <div className="container-auth">
        <Link to="/">
          <img src={AuthLogo} alt="Logo" className="auth-logo"/>
        </Link>
        <div className="container-auth-card">
          <div className="auth-col auth-col-title">
            <h3 className="text-purple">Login</h3>
            <p className="paragraph-lt-sub">
              Do not have an account?&nbsp;&nbsp;<Link to="/auth/register">Sign Up</Link>
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            render={({errors, touched, isSubmitting}) => (
              <div className="form-auth">
                <Form>
                  <Row className="auth-row">
                    <Col className="auth-col" >
                      <FormGroup className="form-input">
                        <Label htmlFor="email" className="auth-label">Email</Label>
                        <Field
                          name="email"
                          type="text"
                          component={CustomFormInput}
                          className={`field-auth ${
                            errors.email && touched.email ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="auth-row">
                    <Col className="auth-col" >
                      <FormGroup className="form-input">
                        <Label htmlFor="password">Password</Label>
                        <Field
                          name="password"
                          type="password"
                          component={CustomFormInput}
                          className={`form-control${
                            errors.password && touched.password ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="auth-row">
                    <Col className="auth-col auth-login-redirects" >
                      <FormGroup className="form-check form-input d-flex" inline>
                        <Label check>
                          <Checkbox type="checkbox" className=""/>
                          Remember me
                        </Label>
                        <Link className="ms-auto text-decoration-none" to="/auth/reset-password">
                          <small>Forgot Pwd?</small>
                        </Link>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="auth-row">
                    <Col className="auth-col" >
                      <FormGroup>
                        <Button type="submit" color="primary" className="btn-ghost" disabled={isSubmitting}>
                          {isSubmitting ? 'Logging in...' : 'Login'}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
    ;
};

export default LoginFormik;
