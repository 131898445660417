import React from 'react';
import { Button, Label, FormGroup, Row, Col } from 'reactstrap';
import {Link, useNavigate} from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomFormInput from "../../../elements/forms/CustomFormInput";
import AuthLogo from "../../../../assets/logo/tethyr_logo_lt_text.svg";
import '../auth.css';
import './resetpwd.css';

const RecoverPassword = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: '',
    uname: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    uname: Yup.string().required('Username is required'),
  });
  return (
    <div className="login-box text-lt">
      <div className="container-auth">
        <Link to="/">
          <img src={AuthLogo} alt="Logo" className="auth-logo"/>
        </Link>
        <div className="container-auth-card">
          <div className="auth-col auth-col-title">
            <h3 className="text-purple">
              Reset Password
            </h3>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(fields) => {
              // eslint-disable-next-line no-alert
              alert(`SUCCESS!! :-)\n\n${JSON.stringify(fields, null, 4)}`);
              navigate('/');
            }}
            render={({errors, touched}) => (
              <div className="form-auth">
                <Form className="">
                  <Row className="auth-row">
                    <Col className="auth-col" >
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Field
                          name="email"
                          type="text"
                          component={CustomFormInput}
                          className={`form-control${
                            errors.email && touched.email ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="auth-row">
                    <Col className="auth-col" >
                      <FormGroup>
                        <Button type="submit" color="info" block className="btn-ghost reset-btn">
                          Reset
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
    ;
};

export default RecoverPassword;
