import React from 'react';
import Header from '../../elements/header/Header';
import Hero from '../../elements/hero/Hero';
import ProductRoadmap from '../../elements/product-roadmap/ProductRoadmap';
import LandingProduct from '../../elements/landing-product/LandingProduct';
import ProductFeatures from '../../elements/product-features/ProductFeatures';
import LandingFooter from '../../elements/landing-footer/LandingFooter';
import './landing-page.css';

const Landing = () => {
  return (
    <>
      <Header />
      <main className='main-landing'>
        <Hero />
        <LandingProduct />
        <ProductFeatures />
        <ProductRoadmap />
      </main>
      <LandingFooter />
    </>
  );
}

export default Landing;