import React from 'react';
import Header from '../../elements/header/Header';
import Footer from '../../elements/footer/Footer';
import {Link} from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className='content-page text-dk'>
        <div className='content-page-wrapper'>
          <h1 className='title-sub-2 bold'>Privacy Policy for Prompt AI University</h1>
          <h4 className='title-sub-4 text-gradient-blue'>Last updated: 10/16/2023</h4>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              1. Overview
            </h3>
            <p className='paragraph-sub-4 semi-bold'>
              Welcome to Prompt AI University, an online platform dedicated to teaching prompt engineering skills to business professionals. This privacy policy outlines our commitment to protecting your privacy and the personal data we collect and use. Please read the following carefully to understand our views and practices.
            </p>
          </div>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              2. Data Collection and Use
            </h3>
            <p className='paragraph-sub-4 semi-bold'>
              <span className='text-gradient-blue bold'>* Personal Information:</span> We collect full names, email addresses, and, for certain services, phone numbers and billing addresses. This information is used to send updates, promotions, and for processing payments for our courses.
            </p>
            <p className='paragraph-sub-4 semi-bold'>
              <span className='text-gradient-blue bold'>* Method:</span> The primary method of data collection is via sign-up forms.
            </p>
          </div>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              3. Data Storage and Protection
            </h3>
            <p className='paragraph-sub-4 semi-bold'>
              Your data is stored securely on cloud servers. To protect your data, we employ robust security measures such as secure logins via Keycloak. Note that specific retrieval processes might require authentication.
            </p>
          </div>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              4. Third-party Sharing and Services
            </h3>
            <p className='paragraph-sub-4 semi-bold'>
              We do not share your data with third-party entities. However, for analytical purposes, we use services like Google Analytics. More third-party services may be introduced in the future.
            </p>
          </div>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              5. User Rights
            </h3>
            <p className='paragraph-sub-4 semi-bold'>
              <span className='text-gradient-blue bold'>* Data Access:</span> To request access to view the data we store about you, email us at <Link to='mailto:contact@promptaiuniversity.com' >contact@promptaiuniversity.com</Link>.
            </p>
            <p className='paragraph-sub-4 semi-bold'>
              <span className='text-gradient-blue bold'>* Data</span> Rectification: If you need to update or modify your data, please reach out to the above email address.
            </p>
            <p className='paragraph-sub-4 semi-bold'>
              <span className='text-gradient-blue bold'>* Data Deletion:</span> You can request the deletion of your data by emailing <Link to='mailto:contact@promptaiuniversity.com' >contact@promptaiuniversity.com</Link>.
            </p>
          </div>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              6. Cookies
            </h3>
            <p className='paragraph-sub-4 semi-bold semi-bold'>
              We currently do not use cookies. If we decide to use cookies in the future, you can manage them or opt out through the unsubscribe link at the bottom of our emails or by reaching out to us directly.
            </p>
          </div>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              7. External Links
            </h3>
            <p className='paragraph-sub-4 semi-bold'>
              While our website does not currently contain external links, should we incorporate them in the future, this policy would not apply to those external websites. We encourage users to be cautious and read the privacy policies of any other sites they visit.
            </p>
          </div>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              8. Policy Updates
            </h3>
            <p className='paragraph-sub-4 semi-bold'>
              Any changes we make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by email and indicated on our website via a banner.
            </p>
          </div>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              9. Compliance and Legal Considerations
            </h3>
            <p className='paragraph-sub-4 semi-bold'>
              We comply with the General Data Protection Regulation (GDPR). As per our GDPR obligations, we have implemented logic to delete inactive inquiries data after 30 days of inactivity. Our platform is primarily designed for working adults, and we do not knowingly collect or solicit information from minors.
            </p>
          </div>
          <div className='paragraph-section'>
            <h3 className='title-sub-3 bold'>
              10. Contact
            </h3>
            <p className='paragraph-sub-4 semi-bold'>
              For questions about this privacy policy or your personal data, please contact us at <Link to='mailto:contact@promptaiuniversity.com' >contact@promptaiuniversity.com</Link> or at our business address: 570 County Road 3202, De Kalb, Tx. 75559.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;