import React from 'react';
import './product-features-card.css';

const ProductFeaturesCard = (props) => {
  const { card } = props;
  
  return (
    <div className={`tethyr-features-card ${card.cardClass}`}>
      <div className='tethyr-features-title'>
        <div className={card.iconContainerClass}>
          <img src={card.icon} className={card.iconClass} alt={card.altText}/>
        </div>
        <h3 className='title-sub-2'>
          {card.title}
        </h3>
      </div>
      <p className="tethyr-features-content">
        {card.content}
      </p>
    </div>
  );
}

export default ProductFeaturesCard;
