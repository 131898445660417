import React from 'react';

const Documents1 = (props) => {
  const { svgClass, pathClass, width, height } = props;
  
  return (
    <svg id="Layer_1" className={svgClass} width={width} height={height} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.05 38">
      <g id="Group_1413371438" data-name="Group 1413371438">
        <g id="Group">
          <g id="Group_2" data-name="Group 2">
            <path id="Vector" className={pathClass}
                  d="m30.22,5.04h-3.2V1.83c0-1.01-.82-1.83-1.83-1.83H1.83C.82,0,0,.82,0,1.83v29.3c0,1.01.82,1.83,1.83,1.83h3.2v3.2c0,1.01.82,1.83,1.83,1.83h15.57c1.01,0,2.43-.56,3.16-1.25l5.12-4.82c.73-.69,1.33-2.08,1.33-3.09V6.87c0-1.01-.82-1.83-1.83-1.83Zm-25.18,1.83v24.72H1.83c-.25,0-.46-.21-.46-.46V1.83c0-.25.21-.46.46-.46h23.35c.25,0,.46.21.46.46v3.2H6.87c-1.01,0-1.83.82-1.83,1.83Zm24.74,24.06l-5.12,4.82c-.1.1-.24.2-.39.29v-4.21c0-.31.13-.6.34-.81.21-.21.49-.34.81-.34h4.58c-.07.1-.14.19-.22.26Zm.9-2.09c0,.14-.02.29-.06.46h-5.2c-1.39,0-2.52,1.13-2.52,2.52v4.75c-.16.03-.32.06-.46.06H6.87c-.25,0-.46-.21-.46-.46V6.87c0-.25.21-.46.46-.46h23.35c.25,0,.46.21.46.46v21.98Z"/>
          </g>
        </g>
        <g id="Group_3" data-name="Group 3">
          <g id="Group_4" data-name="Group 4">
            <path id="Vector_2" data-name="Vector 2" className={pathClass}
                  d="m25.18,11.45h-13.28c-.38,0-.69.31-.69.69s.31.69.69.69h13.28c.38,0,.69-.31.69-.69s-.31-.69-.69-.69Z"/>
          </g>
        </g>
        <g id="Group_5" data-name="Group 5">
          <g id="Group_6" data-name="Group 6">
            <path id="Vector_3" data-name="Vector 3" className={pathClass}
                  d="m25.18,20.6h-13.28c-.38,0-.69.31-.69.69s.31.69.69.69h13.28c.38,0,.69-.31.69-.69s-.31-.69-.69-.69Z"/>
          </g>
        </g>
        <g id="Group_7" data-name="Group 7">
          <g id="Group_8" data-name="Group 8">
            <path id="Vector_4" data-name="Vector 4" className={pathClass}
                  d="m19.23,25.18h-7.33c-.38,0-.69.31-.69.69s.31.69.69.69h7.33c.38,0,.69-.31.69-.69s-.31-.69-.69-.69Z"/>
          </g>
        </g>
        <g id="Group_9" data-name="Group 9">
          <g id="Group_10" data-name="Group 10">
            <path id="Vector_5" data-name="Vector 5" className={pathClass}
                  d="m25.18,16.02h-13.28c-.38,0-.69.31-.69.69s.31.69.69.69h13.28c.38,0,.69-.31.69-.69s-.31-.69-.69-.69Z"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Documents1;
