import React from 'react';
import './product-features.css';
import ProductFeaturesCard from '../product-features-card/ProductFeaturesCard';
import TechLines2 from '../elements-svg/TechLines2';
import connectHands from '../../../assets/icons/connect-hands-v2.svg';
import chatBubbles from '../../../assets/icons/chat-bubbles-v2.svg';
import profileIcon from '../../../assets/icons/profile-thin-v1.svg';
import storeFront from '../../../assets/icons/store-front-v1.svg';
import creditCards from '../../../assets/icons/credit-card-thin-v1.svg';
import groupIcon from '../../../assets/icons/group-v1.svg';
import lockedIcon from '../../../assets/icons/locked-v1.svg';
import nodesIcon from '../../../assets/icons/nodes-v1.svg';
import tethyrBannerImage from "../../../assets/misc/tethyr-network-banner.jpg";
import tethyrBannerImageMobile from "../../../assets/misc/tethyr-network-banner-mobile.png";
import tethyrLogoV1 from "../../../assets/logo/logo-v1.svg";

const ProductFeatures = () => {
  
  const cardContent = [
    {
      url: '',
      cardClass: 'bg-straight-gradient-purple text-lt',
      icon: connectHands,
      iconContainerClass: 'icon-card-circle-md icon-container bg-white',
      iconClass: 'tethyr-icon ',
      title: 'Networking',
      content: 'Connect with like-minded believers on Tethyr. Unlike other platforms, every connection here is united by a shared mission—building the Kingdom of God!',
    },
    {
      url: '',
      cardClass: '',
      icon: chatBubbles,
      iconContainerClass: 'icon-card-circle-md icon-container bg-slanted-gradient-green',
      iconClass: 'tethyr-icon ',
      title: 'Private Chat',
      content: 'Enjoy secure, one-on-one conversations with fellow Kingdom builders. Stay connected and collaborate in a safe, distraction-free space.',
    },
    {
      url: '',
      cardClass: 'transparent',
      icon: profileIcon,
      iconContainerClass: 'icon-card-circle-md icon-container bg-slanted-gradient-yellow',
      iconClass: 'tethyr-icon-md ',
      title: 'Custom Profiles',
      content: 'Build a profile that reflects your unique Kingdom mission. Share your story, showcase your work, and connect with purpose.',
    },
    {
      url: '',
      cardClass: 'transparent',
      icon: storeFront,
      iconContainerClass: 'icon-card-circle-md icon-container bg-slanted-gradient-purple',
      iconClass: 'tethyr-icon ',
      title: 'Business Pages',
      content: 'Showcase your Kingdom-focused business or ministry. Share your mission, connect with other business leaders, and grow your impact.',
    },
    {
      url: '',
      cardClass: 'transparent',
      icon: creditCards,
      iconContainerClass: 'icon-card-circle-md icon-container bg-slanted-gradient-pink',
      iconClass: 'tethyr-icon ',
      title: 'Paypal Wallet',
      content: 'Seamlessly send and receive payments for donations, services, or resources—all integrated within the platform.',
    },
    {
      url: '',
      cardClass: 'transparent',
      icon: groupIcon,
      iconContainerClass: 'icon-card-circle-md icon-container bg-slanted-gradient-orange',
      iconClass: 'tethyr-icon ',
      title: 'Create Events',
      content: 'Organize gatherings, workshops, and more to bring your community together and further your Kingdom-building efforts.',
    },
    {
      url: '',
      cardClass: 'transparent',
      icon: lockedIcon,
      iconContainerClass: 'icon-card-circle-md icon-container bg-slanted-gradient-blue',
      iconClass: 'tethyr-icon-md ',
      title: 'Memberships',
      content: 'Create a membership site to charge for private mentoring/coaching and connect to our PayPal Tethyr Wallet for a seamless transaction.',
    },
    {
      url: '',
      cardClass: 'transparent',
      icon: nodesIcon,
      iconContainerClass: 'icon-card-circle-md icon-container bg-slanted-gradient-light-pink',
      iconClass: 'tethyr-icon ',
      title: 'Too Much to List!',
      content: 'Tethyr is such a robust platform, that you just have to try it to see it\'s full potential. Request to JOIN NOW!',
    },
  ]
  
  return (
    <section className='tethyr-intro'id='tethyr-features-intro'>
      <div className='tethyr-intro-svg-left'>
        <TechLines2 width='300px' fillClass="fill-dark" />
      </div>
      <div className='tethyr-intro-svg-right'>
        <TechLines2 width='300px' fillClass="fill-dark"/>
      </div>
      <div className="tethyr-banner">
        <img src={tethyrBannerImage} className="tethyr-banner-img" alt="Tethyr Banner"/>
        <img src={tethyrBannerImageMobile} className="tethyr-banner-img-mobile" alt="Tethyr Banner"/>
      </div>
      <div className='tethyr-intro-content'>
        <img src={tethyrLogoV1} alt="Tethyr Logo V1"/>
        <p className='paragraph-lt'>
          Tethyr is a powerful tool for Kingdom builders with a purpose. Combining the familiarity of a social media platform with features that foster meaningful connections, it’s designed to help you make a real impact for the Kingdom of God.
        </p>
        <p className='paragraph-lt semi-bold tethyr-feature-callout'>
          Here are a few of the features you can expect from Tethyr v.1!
        </p>
      </div>
      <div className='wrapper-what-to-do-bottom'>
        {cardContent.map((card, index) =>
          <ProductFeaturesCard key={index} card={card} index={index}/>
        )}
      </div>
    </section>
  );
}

export default ProductFeatures;
