import React from "react";
import {Link} from 'react-router-dom';
import './not-found.css';
import Header from '../../elements/header/Header';
import Footer from '../../elements/footer/Footer';

const NotFound = () => {
  return (
    <>
      <Header />
      <main className='not-found'>
        <h1>
          CONGRATULATIONS!
        </h1>
        <p>You found our secret page... that doesn't exist ;)<br/>Here's a <Link className='text-vivid link' to='/'>link</Link> to the real stuff.<br/>
        </p>
      </main>
      <Footer />
    </>
    
  );
}

export default NotFound;