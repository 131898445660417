import React from 'react';
import { Link } from 'react-router-dom';

const CTAButton = (props) => {
  const { className, icon, content, url, btnType, statusColor } = props;
  
  if (btnType === 'submit') {
    return (
      <div className={className}>
        <input type="submit" value={content} className='bg-clear no-border text-lt' />
        {icon}
      </div>
    );
  }
  
  return (
    <Link to={url ? url : ''}>
      <button type={btnType} className={`${className} ${statusColor}`}>
        {content} {icon}
      </button>
    </Link>
  );
}

export default CTAButton;
