import React from 'react';
import './product-roadmap.css';
import Monitor1 from '../elements-icons/Monitor1';
import HandBrain1 from '../elements-icons/HandBrain1';
import Documents1 from '../elements-icons/Documents1';
import ProductRoadmapCard from '../product-roadmap-card/ProductRoadmapCard';
import tethyrBannerImage from "../../../assets/misc/tethyr-roadmap-banner.jpg";
import tethyrBannerImageMobile from "../../../assets/misc/tethyr-roadmap-banner-mobile.jpg";
import TechLines2 from "../elements-svg/TechLines2";

const ProductRoadmap = () => {

  const cardContent = [
    {
      url: '/#product-roadmap',
      cardClass: 'bg-straight-gradient-purple',
      icon: <Monitor1 svgClass='icon-lg' pathClass='icon-pink' />,
      iconClass: 'icon-card-circle-lg icon-container bg-white',
      title: 'Phase 1',
      content: 'Launching Tethyr v.1 is where we start. Our focus is to create a solid foundation for the Tethyr platform with the essential tools you\'d expect from any social network—but customized to empower the unique mission of Kingdom Builders.',
      status: 'COMPLETE!',
      statusColor: 'text-lt-green',
    },
    {
      url: '/#product-roadmap',
      cardClass: 'bg-straight-gradient-black',
      icon: <Documents1 svgClass='icon-lg' pathClass='icon-wht' />,
      iconClass: 'icon-card-circle-lg icon-container bg-slanted-gradient-green',
      title: 'Phase 2',
      content: 'Expanding the network with purpose. We will thoughtfully and prayerfully onboard Kingdom Builders and introduce new features as the community grows, ensuring every step aligns with our mission and the needs of our members.',
      status: 'GEARING UP!',
      statusColor: 'text-orange'
    },
    {
      url: '/#product-roadmap',
      cardClass: 'bg-straight-gradient-black',
      icon: <HandBrain1 svgClass='icon-lg' pathClass='icon-wht' />,
      iconClass: 'icon-card-circle-lg icon-container bg-slanted-gradient-orange',
      title: 'Phase 3',
      content: 'Advancing growth with innovation. This phase will introduce powerful tools like AI for resource sharing and event management, expand global discipleship initiatives, and address broader Kingdom-wide needs to equip our community for lasting impact.',
      status: 'FUTURE',
      statusColor: 'text-orange'
    }
  ]

  return (
    <section className='section-product-roadmap text-dk' id='product-roadmap'>
      <div className='tethyr-roadmap-svg-left'>
        <TechLines2 width='300px' fillClass="fill-grey"/>
      </div>
      <div className='tethyr-roadmap-svg-right'>
        <TechLines2 width='300px' fillClass="fill-grey"/>
      </div>
      <div className="tethyr-roadmap-banner">
        <img src={tethyrBannerImage} className="tethyr-roadmap-banner-img" alt="Tethyr Banner"/>
        <img src={tethyrBannerImageMobile} className="tethyr-roadmap-banner-img-mobile" alt="Tethyr Banner"/>
      </div>
      <div className='product-roadmap-intro'>
        <h2 className='title-sub-1'>
          Dev Roadmap
        </h2>
        <p className='paragraph-dk semi-bold'>
          For developing Tethyr, we are taking a 3 phase approach. Below is a quick overview of each phase and what you
          can expect from the platform.
        </p>
        <p className='paragraph-dk semi-bold product-roadmap-callout'>
          Let's take a quick look at the plan:
        </p>
      </div>
      <div className='product-roadmap-cards'>
        {cardContent.map((card, index) =>
          <ProductRoadmapCard key={index} card={card} index={index}/>
        )}
      </div>
      <div className="product-roadmap-crowdfund">

      </div>
    </section>
  );
}

export default ProductRoadmap;
